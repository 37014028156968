import Head from 'next/head';

export const ErrorHead = () => {
  return (
    <Head>
      <title>Liander - Pagina niet gevonden</title>
      <link rel="icon" href="/assets/favicon.png" key="icon" />

      <link rel="apple-touch-icon" href="/assets/apple-touch-icon.png" />
      <link rel="apple-touch-icon-precomposed" href="/assets/apple-touch-icon-precomposed.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/assets/apple-touch-icon-120x120.png" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="/assets/apple-touch-icon-120x120-precomposed.png"
      />
      <link rel="apple-touch-icon" sizes="152x152" href="/assets/apple-touch-icon-152x152.png" />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="/assets/apple-touch-icon-152x152-precomposed.png"
      />
    </Head>
  );
};
