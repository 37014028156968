import {
  Stack,
  PageGrid,
  GridItem,
  Box,
  NavLink,
  ToneFocus,
  StackItem,
} from '@ads-core/components';
import Link from 'next/link';
import { SearchIcon } from '@ads-core/icons';
import { useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';
import Image from 'next/image';
import NextLink from 'next/link';

export const ErrorNavigation = () => {
  const isDesktop = useMedia(mq.md);
  return (
    <Box paddingBottom={10}>
      <Box bg="containerDark" width="100%">
        <Box paddingRight={10}>
          <PageGrid>
            <GridItem columnStart="1" columnEnd="-1">
              <Box asChild paddingTop={4} paddingBottom={4}>
                <Stack
                  direction={{ initial: 'column', md: 'row' }}
                  gap={{ initial: 2, md: 6 }}
                  alignY={{ initial: 'start', md: 'center' }}
                  isFullWidth
                >
                  <Box paddingBottom={{ initial: 4, md: 0 }}>
                    <Stack
                      direction={{ initial: 'row', md: 'row' }}
                      gap={{ initial: 2, md: 6 }}
                      alignY="center"
                      alignX="justify"
                    >
                      <ToneFocus tone="onDark">
                        <Link href="/">
                          <Box asChild width="104px">
                            <Image
                              src="/assets/logo.png"
                              alt="logo Liander"
                              width="292"
                              height="136"
                            />
                          </Box>
                        </Link>
                      </ToneFocus>

                      {!isDesktop && (
                        <ToneFocus tone="onDark">
                          <Link href="/zoeken">
                            <SearchIcon size="md" color="onDark" />
                          </Link>
                        </ToneFocus>
                      )}
                    </Stack>
                  </Box>

                  <Stack direction="row" alignY="center" alignX="justify" isFullWidth>
                    <Stack
                      direction={{ initial: 'column', sm: 'row' }}
                      gap={{ initial: 2, md: 6 }}
                      alignY={{ initial: 'justify', md: 'start' }}
                      alignX="start"
                      isFullWidth
                    >
                      <NavLink tone="onDark" asChild size="large">
                        <NextLink href="/">Thuis en kleinzakelijk</NextLink>
                      </NavLink>
                      <NavLink tone="onDark" asChild size="large">
                        <NextLink href="/grootzakelijk">Grootzakelijk</NextLink>
                      </NavLink>
                      <NavLink tone="onDark" asChild size="large">
                        <NextLink href="/over-ons">Over ons</NextLink>
                      </NavLink>
                    </Stack>
                    {isDesktop && (
                      <StackItem shrink={false}>
                        <ToneFocus tone="onDark">
                          <Link href="/zoeken">
                            <SearchIcon size="md" color="onDark" />
                          </Link>
                        </ToneFocus>
                      </StackItem>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </GridItem>
          </PageGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorNavigation;
